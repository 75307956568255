import * as React from "react";

const SignOutIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M14 7a1 1 0 1 0 2 0V6a4 4 0 0 0-3.998-4H6.005a4 4 0 0 0-3.998 4v12a4 4 0 0 0 3.998 4h6.004a4 4 0 0 0 3.998-4v-1a1 1 0 1 0-2 0v1a2 2 0 0 1-1.998 2H6.005a2 2 0 0 1-1.999-2V6a2 2 0 0 1 2-2h5.996A2 2 0 0 1 14 6v1Z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.3}
      />
      <rect
        fill="#000"
        opacity={0.3}
        transform="rotate(90 14 12)"
        x={13}
        y={6}
        width={2}
        height={12}
        rx={1}
      />
      <path
        d="M17.293 9.707a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414l-3 3a1 1 0 1 1-1.414-1.414L19.586 12l-2.293-2.293Z"
        fill="#000"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SignOutIcon;
