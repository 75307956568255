import { toast } from "react-toastify";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();
      if (authToken) {
        console.log(authToken);
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (response) =>
      new Promise(async (resolve, reject) => {
        return resolve(response);
      }),
    (error) =>
      new Promise(async (resolve, reject) => {
        if (error.response) {
          console.log(error.response);
          console.log("axios-response-error", JSON.stringify(error.response));
        } else {
          console.log("axios-response-error", error.response);
        }
        if (error.response) {
          toast.error("Error occured.");
          if (error.response.status === 401) {
            toast.error("Unauthorized access.\nPlease login again.");
          } else if (error.response.status === 404) {
            toast.error("Record not found!");
          } else if (error.response.status === 400) {
            toast.error(error.response.msg);
          } else if (error.response.status === 500) {
            toast.error(
              error.response.data?.msg
                ? error.response.data?.msg
                : error.response.data?.message
            );
          } else if (error.response.status === 403) {
            toast.error("Unable to process required request.");
          } else if (error.response.status === 413) {
            toast.error("File attached is too large.");
          } else {
            if (error.response.data?.msg) {
              toast.error(error.response.data.msg);
            }
          }
          reject({
            status: error.response.status,
            response: error.response.data,
          });
        } else {
          toast.error("Error occured.");
          reject(error);
        }
      })
  );
}
