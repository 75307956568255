import React, { useState } from "react";
import { Button, TextField, Grid } from "@material-ui/core";
function SearchForTable({ onSubmitValue }) {
  const [value, setValue] = useState("");
  return (
    <>
      <div>
        <Grid container>
          <Grid item xs={9}>
            <TextField
              className="inputRounded"
              style={{ borderRadius: "0px", width: "100%" }}
              color="secondary"
              onChange={(e) => setValue(e.target.value)}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={() => onSubmitValue(value)}
              color="secondary"
              variant="contained"
              className="btn btn-primary btn-sm ml-2"
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
export default SearchForTable;
