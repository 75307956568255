import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import Uploader from "./modules/ECommerce/pages/Uploader";
import Progress from "./modules/ECommerce/pages/Progress";
import Users from "./modules/ECommerce/pages/Users";
import Sizes from "./modules/ECommerce/pages/Sizes";
import FieldType from "./modules/ECommerce/pages/FieldType";
import Insects from "./modules/ECommerce/pages/Insects";
import Company from "./modules/ECommerce/pages/Company";
import Field from "./modules/ECommerce/pages/Field";
const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute exact path="/dashboard" component={DashboardPage} />
        <ContentRoute exact path="/builder" component={BuilderPage} />
        <ContentRoute exact path="/my-page" component={MyPage} />
        <Route exact path="/google-material" component={GoogleMaterialPage} />
        <Route exact path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route exact path="/e-commerce" component={ECommercePage} />
        <Route exact path="/progress" component={Progress} />
        <Route exact path="/uploader" component={Uploader} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/sizes" component={Sizes} />
        <Route exact path="/field-type" component={FieldType} />
        <Route exact path="/insects" component={Insects} />
        <Route exact path="/company" component={Company} />
        <Route exact path="/field" component={Field} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
