import React from "react";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../_helpers";

function Link({ link, name, icon, location, onClick }) {
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  return (
    <li
      onClick={onClick}
      className={`menu-item ${getMenuItemActive(link, false)}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to={link}>
        <span className="svg-icon menu-icon">{icon}</span>
        <span className="menu-text">{name}</span>
      </NavLink>
    </li>
  );
}
export default Link;
