import React, { Component } from "react";
import { withRouter } from "react-router-dom"; 
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import Image from "./Components/Image";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
var URL = process.env.REACT_APP_API_URL;
class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  GetImages = async () => {
    toast.success("Loading data...",);
    axios.get(`${URL}/images/1`).then(async (res) => {
      if (!isEmpty(res.data.data)) {
        toast.success("Data loaded!",{
          autoClose: 5000,
        });
        this.setState({
          data: res.data.data,
        });
      } else {
        toast.error("No data found!",{
          autoClose: 5000,
        });
      }
    });
  };

  componentWillMount() {
    this.GetImages();
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-1 col-xl-2 col-md-1 col-sm-12 col-xs-12"></div>
          <div className="col-lg-10 col-xl-8 col-md-10 col-sm-12 col-xs-12">
            <Card>
              <CardHeader
                title="Progress Viewer"
                icon={
                  <i
                    className="flaticon-upload-1 mr-3 "
                    style={{ fontSize: "30px", color: "#3F4254" }}
                  ></i>
                }
              ></CardHeader>
              <CardBody>
                <div className="row">
                  {this.state.data ? (
                    this.state.data.map((obj) => (
                      <Image
                        status={obj.status}
                        stage={obj.stage}
                        file={obj.gdrive_filename}
                        g_drive_file_url={obj.g_drive_file_url}
                        file_id={obj.gdrive_uid}
                      />
                    ))
                  ) : (
                    <h3>Loading...</h3>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-1 col-xl-2 col-md-1 col-sm-12 col-xs-12"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Progress);
