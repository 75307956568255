/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
// import React, { useMemo } from "react";
import React from "react";
// import { useSelector } from "react-redux";
// import objectPath from "object-path";
// import { useHtmlClassService } from "../../../_core/MetronicLayout";v
// import { useHistory } from "react-router-dom";

export function UserProfileDropdown() {
  // const { user } = useSelector((state) => state.auth);
  // const history = useHistory();
  // const uiService = useHtmlClassService();
  // const logoutClick = () => {
  //   localStorage.removeItem("access_token");
  //   localStorage.removeItem("permission");
  //   const toggle = document.getElementById("kt_quick_user_toggle");
  //   if (toggle) {
  //     toggle.click();
  //   }
  //   history.push("/logout");
  // };
  // const layoutProps = useMemo(() => {
  //   return {
  //     light:
  //       objectPath.get(uiService.config, "extras.user.dropdown.style") ===
  //       "light",
  //   };
  // }, [uiService]);

  return <></>;
}
