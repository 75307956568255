import * as React from "react";

const HomeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M3.957 8.415 11.48 3.82a1 1 0 0 1 1.042 0l7.522 4.596A2 2 0 0 1 21 10.122V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-8.878a2 2 0 0 1 .957-1.707ZM10 13a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-4Z"
        fill="#000"
      />
    </g>
  </svg>
);

export default HomeIcon;
