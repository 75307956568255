// import { createMD5 } from "hash-wasm";

// export const HashFile = async (file) => {
//   console.log("In function");
//   const chunkSize = 64 * 1024 * 1024;
//   const fileReader = new FileReader();
//   let hasher = await createMD5();
//   fileReader.onload = async (e) => {
//     const view = new Uint8Array(e.target.result);
//     hasher.update(view);
//     // resolve(hasher.update(view));
//   };

//   function hashChunk(chunk) {
//     // return new Promise((resolve, reject) => {
//     // });
//   }

//   const readFile = async (file) => {
//     if (hasher) {
//       hasher.init();
//     }

//     const chunkNumber = Math.floor(file.size / chunkSize);

//     for (let i = 0; i <= chunkNumber; i++) {
//       const chunk = file.slice(
//         chunkSize * i,
//         Math.min(chunkSize * (i + 1), file.size)
//       );
//       fileReader.readAsArrayBuffer(chunk);
//     }

//     const hash = hasher.digest();
//     return Promise.resolve(hash);
//   };

//   const hash = await readFile(file);

//   return hash;
// };

import { createMD5 } from "hash-wasm";

export const HashFile = async (file) => {
  const chunkSize = 64 * 1024 * 1024;
  const fileReader = new FileReader();
  let hasher = null;

  function hashChunk(chunk) {
    return new Promise((resolve, reject) => {
      fileReader.onload = async (e) => {
        const view = new Uint8Array(e.target.result);
        resolve(hasher.update(view));
      };

      fileReader.readAsArrayBuffer(chunk);
    });
  }

  const readFile = async (file) => {
    if (hasher) {
      hasher.init();
    } else {
      hasher = await createMD5();
    }

    const chunkNumber = Math.floor(file.size / chunkSize);

    for (let i = 0; i <= chunkNumber; i++) {
      const chunk = file.slice(
        chunkSize * i,
        Math.min(chunkSize * (i + 1), file.size)
      );
      await hashChunk(chunk);
    }

    const hash = hasher.digest();
    return Promise.resolve(hash);
  };

  const hash = await readFile(file);

  return hash;
};
