import React, { Component } from "react";
import { withRouter } from "react-router";
import SearchForTable from "../../Component/SearchForTable";
import $ from "jquery";
import axios from "axios";
import isEmpty from "../../../Validations/is-empty";
import { Form, Table } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import Select from "react-select";
const URL = process.env.REACT_APP_API_URL;

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_title: "Add User",
      button_title: "Add",
      action: "Save",
      username: "",
      first_name: "",
      last_name: "",
      user_type: "user",
      password: "",
      data: [],
      id: 0,
      search: "",
      pageNo: 1,
      itemsPerPage: "20",
      companies: [],
      fields: [],
      companiesList: [],
      fieldsList: [],
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
    this.PaginateData = this.PaginateData.bind(this);
    this.GetTotalPages = this.GetTotalPages.bind(this);
  }

  PaginateData(PageNo) {
    this.setState({ pageNo: PageNo + "" }, () => {
      axios
        .get(
          `${URL}/user?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
        )
        .then((res) => {
          this.setState(
            {
              data: res.data.users.rows,
              totalPages: Math.ceil(
                res.data.users.count / Number(this.state.itemsPerPage)
              ),
              totalItems: res.data.users.count,
            },
            () => {
              this.SetButtons();
            }
          );
        });
    });
  }

  async GetTotalPages() {
    await axios
      .get(
        `${URL}/user?page=1&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then((res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.users.rows,
            totalPages: Math.ceil(
              res.data.users.count / Number(this.state.itemsPerPage)
            ),
            totalItems: res.data.users.count,
          },
          () => {
            this.SetButtons();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  GetPaginatedData = () => {
    axios
      .get(
        `${URL}/user?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then((res) => {
        this.setState(
          {
            data: res.data.users.rows,
            totalPages: Math.ceil(
              res.data.users.count / Number(this.state.itemsPerPage)
            ),
            totalItems: res.data.users.count,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  GetPageData = (id) => {
    var data = {
      itemsPerPage: this.state.itemsPerPage,
      search: this.state.searchText,
      pageNo: this.state.pageNo,
    };
    if (id === "1") {
      this.setState({ pageNo: $("#bnt1").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "2") {
      this.setState({ pageNo: $("#bnt2").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "3") {
      this.setState({ pageNo: $("#bnt3").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "4") {
      this.setState({ pageNo: $("#bnt4").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "5") {
      this.setState({ pageNo: $("#bnt5").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    }
    axios
      .get(
        `${URL}/user?page=${id}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then(async (res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.users.rows,
            totalPages: Math.ceil(
              res.data.users.count / Number(this.state.itemsPerPage)
            ),
            totalItems: res.data.users.count,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  PreviousPageData = () => {
    if (this.state.pageNo !== 1) {
      this.setState({ pageNo: Number(this.state.pageNo) - 1 + "" }, () => {
        axios
          .get(
            `${URL}/user?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
          )
          .then(async (res) => {
            this.setState(
              {
                data: res.data.users.rows,
                totalPages: Math.ceil(
                  res.data.users.count / Number(this.state.itemsPerPage)
                ),
                totalItems: res.data.users.count,
              },
              () => {
                this.SetButtons();
              }
            );
          });
      });
    }
  };

  NextPageData = () => {
    this.setState({ pageNo: Number(this.state.pageNo) + 1 + "" }, () => {
      axios
        .get(
          `${URL}/user?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
        )
        .then(async (res) => {
          this.setState(
            {
              data: res.data.users.rows,
              totalPages: Math.ceil(
                res.data.users.count / Number(this.state.itemsPerPage)
              ),
              totalItems: res.data.users.count,
            },
            () => {
              this.SetButtons();
            }
          );
        });
    });
  };

  onSubmitValue = (val) => {
    console.log(val);
    this.setState({ search: val ? val : "" });
    axios
      .get(
        `${URL}/user?page=1&limit=${this.state.itemsPerPage}&search=${val}`
      )
      .then(async (res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.users.rows,
            totalPages: Math.ceil(
              res.data.users.count / Number(this.state.itemsPerPage)
            ),
            totalItems: res.data.users.count,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  SetButtons = () => {
    if (parseInt(this.state.pageNo) === 1) {
      $("#btnFirst").prop("disabled", true);
      $("#btnPrevious").prop("disabled", true);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      $("#bnt1").removeClass("btn-outline-primary");
      $("#bnt1").addClass("btn-primary");
      $("#bnt2").removeClass("btn-primary");
      $("#bnt2").addClass("btn-outline-primary");
      $("#bnt4").removeClass("btn-primary");
      $("#bnt4").addClass("btn-outline-primary");
      $("#bnt3").removeClass("btn-primary");
      $("#bnt3").addClass("btn-outline-primary");
      $("#bnt5").removeClass("btn-primary");
      $("#bnt5").addClass("btn-outline-primary");
      $("#bnt1").html("1");
      $("#bnt2").html("2");
      $("#bnt3").html("3");
      $("#bnt4").html("4");
      $("#bnt5").html("5");
      if (parseInt(this.state.totalPages) === 1) {
        $("#btnNext").prop("disabled", true);
        $("#btnLast").prop("disabled", true);
      }
    } else if (
      parseInt(this.state.pageNo) === parseInt(this.state.totalPages)
    ) {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", true);
      $("#btnLast").prop("disabled", true);
      if (parseInt(this.state.totalPages) < 6) {
        $("#bnt" + this.state.totalPages).removeClass("btn-outline-primary");
        $("#bnt" + this.state.totalPages).addClass("btn-primary");
        for (var i = parseInt(this.state.totalPages) - 1; i > 0; i--) {
          $("#bnt" + i).removeClass("btn-primary");
          $("#bnt" + i).addClass("btn-outline-primary");
        }
      } else {
        $("#bnt5").removeClass("btn-outline-primary");
        $("#bnt5").addClass("btn-primary");
        $("#bnt4").removeClass("btn-primary");
        $("#bnt4").addClass("btn-outline-primary");
        $("#bnt3").removeClass("btn-primary");
        $("#bnt3").addClass("btn-outline-primary");
        $("#bnt2").removeClass("btn-primary");
        $("#bnt2").addClass("btn-outline-primary");
        $("#bnt1").removeClass("btn-primary");
        $("#bnt1").addClass("btn-outline-primary");

        $("#bnt1").html("" + (parseInt(this.state.pageNo) - 4));
        $("#bnt2").html("" + (parseInt(this.state.pageNo) - 3));
        $("#bnt3").html("" + (parseInt(this.state.pageNo) - 2));
        $("#bnt4").html("" + (parseInt(this.state.pageNo) - 1));
        $("#bnt5").html("" + parseInt(this.state.pageNo));
      }
    } else {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      if (parseInt(this.state.totalPages) < 6) {
        for (var j = parseInt(this.state.totalPages); j > 0; j--) {
          $("#bnt" + j).removeClass("btn-primary");
          $("#bnt" + j).addClass("btn-outline-primary");
        }
        $("#bnt" + this.state.pageNo).removeClass("btn-outline-primary");
        $("#bnt" + this.state.pageNo).addClass("btn-primary");
      } else {
        if (parseInt(this.state.pageNo) === 2) {
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-primary");
          $("#bnt3").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-outline-primary");
          $("#bnt2").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else if (parseInt(this.state.pageNo) === 3) {
          $("#bnt1").html("1");
          $("#bnt2").html("2");
          $("#bnt3").html("3");
          $("#bnt4").html("4");
          $("#bnt5").html("5");
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-primary");
          $("#bnt2").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-outline-primary");
          $("#bnt3").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else {
          if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            2
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt4").removeClass("btn-primary");
            $("#bnt4").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt3").removeClass("btn-outline-primary");
            $("#bnt3").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) ===
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 3));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt3").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt4").html("" + parseInt(this.state.pageNo));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt3").removeClass("btn-primary");
            $("#bnt3").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt4").removeClass("btn-outline-primary");
            $("#bnt4").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          }
        }
      }
    }
  };

  Search = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.GetTotalPages();
    }
  };

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  handleDeleteALert = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this?</p>
            <button
              className="btn btn-danger"
              onClick={onClose}
              style={{ marginRight: 10 }}
            >
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.Delete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  Delete = (id) => {
    this.setState({
      msg: "Deleting User...",
      error: "",
    });
    axios.delete(`${URL}/user/${id}`).then(async (res) => {
      toast.success(res.data.message);
      this.setState(
        {
          msg: res.data.message,
          error: "",
          id: "",
          username: "",
          first_name: "",
          last_name: "",
          user_type: "user",
          password: "",
          action: "Save",
          modal_title: "Add User",
          button_title: "Add",
        },
        () => {
          this.GetTotalPages();
        }
      );
    });
  };

  UpdateData = (obj) => {
    this.setState({
      id: obj.id,
      username: obj.username,
      first_name: obj.first_name,
      last_name: obj.last_name,
      user_type: obj.user_type,
      modal_title: "Update User",
      button_title: "Update",
      action: "Update",
    });
    if (!isEmpty(obj.company)) {
      let selectedItems = [];
      // obj.companies.map((v) =>
      //   selectedItems.push({
      //     value: v.id.toString(),
      //     label: v.title.toString(),
      //   })
      // );
      this.setState({
        companiesList: {
          value: obj.company.id.toString(),
          label: obj.company.title.toString(),
        },
      });
    } else {
      this.setState({ companiesList: [] });
    }
    if (!isEmpty(obj.fields)) {
      let selectedItems = [];
      obj.fields.map((v) =>
        selectedItems.push({
          value: v.uid.toString(),
          label: v.title.toString(),
        })
      );
      this.setState({
        fieldsList: [...selectedItems],
      });
    } else {
      this.setState({ fieldsList: [] });
    }
    return "";
  };

  AddData = () => {
    this.setState({
      id: 0,
      username: "",
      first_name: "",
      last_name: "",
      user_type: "user",
      password: "",
      modal_title: "Add User",
      button_title: "Add",
      action: "Save",
      companiesList: [],
      fieldsList: [],
    });

    this.GetUnAssignedCompanies();
  };

  GetUnAssignedCompanies = () => {
    axios
      .get(`${URL}/company?page=1&limit=1000`)
      .then(async (res) => {
        var companies = [];
        if (res.data.companies.rows) {
          for (var i = 0; i < res.data.companies.rows.length; i++) {
            companies.push({
              value: res.data.companies.rows[i].id + "",
              label: res.data.companies.rows[i].title,
            });
          }
        }
        await this.setState({
          companies,
        });
      });
  };
  GetFields = () => {
    axios.get(`${URL}/field?company_sort=true&page=1&limit=1000`).then(async (res) => {
      var fields = [];
      if (res.data.fields.rows) {
        for (var i = 0; i < res.data.fields.rows.length; i++) {
          fields.push({
            value: res.data.fields.rows[i].uid,
            label: `${res.data.fields.rows[i].company.title}, ${res.data.fields.rows[i].title}`,
          });
        }
      }
      await this.setState({
        fields,
      });
    });
  };
  componentWillMount() {
    this.GetTotalPages();
    this.GetUnAssignedCompanies();
    this.GetFields();
  }

  Validate = () => {
    if (isEmpty(this.state.first_name)) {
      toast.error("Please enter first name!");
      return false;
    } else if (isEmpty(this.state.last_name)) {
      toast.error("Please enter last name!");
      return false;
    } else if (isEmpty(this.state.username)) {
      toast.error("Please enter username!");
      return false;
    }
    if (this.state.action === "Save") {
      if (isEmpty(this.state.password)) {
        toast.error("Please enter password!");
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  async Save() {
    let isValid = await this.Validate();
    if (isValid) {
      var obj = {
        username: this.state.username,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        user_type: this.state.user_type,
      };
      if (!isEmpty(this.state.companiesList)) {
        // for (var i = 0; i < this.state.companiesList.length; i++) {
        //   if (i === 0) {
        //     companies = [Number(this.state.companiesList[i].value)];
        //   } else {
        //     companies.push(Number(this.state.companiesList[i].value));
        //   }
        // }
        obj = { ...obj, company_id: this.state.companiesList.value };
      }
      if (!isEmpty(this.state.fieldsList)) {
        var fields = [];
        if (this.state.user_type == "advisor") {
          for (var i = 0; i < this.state.fieldsList.length; i++) {
            fields.push(this.state.fieldsList[i].value);
          }
        }
        obj = { ...obj, fields };
      }
      if (!isEmpty(this.state.password));
      obj = { ...obj, password: this.state.password };
      if (this.state.action === "Save") {
        toast.success("Creating user...!");
        axios.post(`${URL}/user`, obj).then(async (res) => {
          toast.success("User Created Successfully!");
          this.AddData();
          this.GetTotalPages();
        });
      } else {
        toast.success("Updating user...");

        axios.put(`${URL}/user/${this.state.id}`, obj).then(async (res) => {
          toast.success("User Updated Successfully!");
          this.AddData();
          this.GetTotalPages();
        });
      }
    }
  }

  onChange(e) {
    var name = e.target.name;
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (name === "itemsPerPage") {
        this.GetTotalPages();
      }
    });
  }

  CompanyChanges = (selectedMulti) => {
    this.setState({ companiesList: selectedMulti });
  };
  FieldChanges = (selectedMulti) => {
    this.setState({ fieldsList: selectedMulti });
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <i
            className="flaticon-user mr-3"
            style={{
              fontSize: "35px",
              color: "#3F4254",
              fontWeight: "900",
            }}
          ></i>
          <h3 style={{ marginBottom: "1.2%" }}>Users</h3>
        </div>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            ></div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <Card>
                <CardHeader title={this.state.modal_title}></CardHeader>
                <CardBody>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <div className="input-group ">
                      <input
                        onKeyPress={this.CheckKey}
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="Enter First Name"
                        value={this.state.first_name}
                        name="first_name"
                        onChange={this.onChange}
                      ></input>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i
                            className="fa fa-user"
                            style={{ fontSize: "13px" }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <div className="input-group ">
                      <input
                        onKeyPress={this.CheckKey}
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="Enter Last Name"
                        value={this.state.last_name}
                        name="last_name"
                        onChange={this.onChange}
                      ></input>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i
                            className="fa fa-user"
                            style={{ fontSize: "13px" }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </Form.Group>{" "}
                  <Form.Group>
                    <Form.Label>User Type</Form.Label>
                    <select
                      className="form-control form-control-sm"
                      name="user_type"
                      value={this.state.user_type}
                      onChange={this.onChange}
                    >
                      <option value="user">User</option>
                      <option value="advisor">Advisor</option>
                      <option value="admin">Admin</option>
                      <option value="superAdmin">Super Admin</option>
                    </select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Username</Form.Label>
                    <div className="input-group ">
                      <input
                        onKeyPress={this.CheckKey}
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="Enter Username"
                        value={this.state.username}
                        name="username"
                        onChange={this.onChange}
                      ></input>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i
                            className="fa fa-envelope"
                            style={{ fontSize: "13px" }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <div className="input-group ">
                      <input
                        onKeyPress={this.CheckKey}
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="Enter Password"
                        value={this.state.password}
                        name="password"
                        onChange={this.onChange}
                      ></input>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i
                            className="fa fa-key"
                            style={{ fontSize: "13px" }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </Form.Group>
                  {this.state.user_type != "advisor" && (<Form.Group>
                    <Form.Label>Companies</Form.Label>
                    <Select
                      placeholder="Select Compnies"
                      value={this.state.companiesList}
                      onChange={this.CompanyChanges}
                      options={this.state.companies}
                      classNamePrefix="select2-selection"
                    />
                  </Form.Group>
                  )}
                  {this.state.user_type == "advisor" && (
                    <Form.Group>
                      <Form.Label>Fields</Form.Label>
                      <Select
                        placeholder="Select Fields"
                        value={this.state.fieldsList}
                        isMulti={true}
                        onChange={(selectedMulti) =>
                          this.FieldChanges(selectedMulti)
                        }
                        options={this.state.fields}
                        classNamePrefix="select2-selection"
                      />
                    </Form.Group>
                  )}
                  <button
                    className="btn btn-primary"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    {this.state.button_title}
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => this.AddData()}
                    name="cancel"
                    style={{ float: "left" }}
                  >
                    Cancel
                  </button>
                </CardBody>
              </Card>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-8">
              <Card>
                <CardHeader title="Users">
                  <CardHeaderToolbar>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.AddData()}
                      name="add_button"
                    >
                      Add New User
                    </button>
                  </CardHeaderToolbar>
                </CardHeader>
                <>
                  <div>
                    <SearchForTable onSubmitValue={this.onSubmitValue} />{" "}
                  </div>
                  <CardBody ref={(el) => (this.componentRef = el)}>
                    <div className="row d-none print">
                      <div className="col-sm-12">
                        <span>
                          <h2 className="text-center">Users</h2>
                        </span>
                      </div>
                    </div>

                    <Table responsive>
                      <thead>
                        <tr>
                          <th width="10%">
                            <span style={{ display: "flex" }}>Sr#</span>
                          </th>
                          <th width="25%">
                            <span style={{ display: "flex" }}>Name</span>
                          </th>
                          <th width="15%">
                            <span style={{ display: "flex" }}>Username</span>
                          </th>
                          <th width="30%">
                            <span style={{ display: "flex" }}>
                              Company {"&"} Fields
                            </span>
                          </th>
                          <th width="10%">
                            <span style={{ display: "flex" }}>Type</span>
                          </th>
                          <th width="10%" className="text-center">
                            Events
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data ? (
                          this.state.data.map((obj, k) => (
                            <tr>
                              <td>
                                {this.state.itemsPerPage *
                                  (this.state.pageNo - 1) +
                                  k +
                                  1}
                              </td>
                              <td>
                                {obj.first_name} {obj.last_name}
                              </td>
                              <td>{obj.username}</td>
                              <td>
                                {obj.company &&
                                  <span>
                                    <b>{obj.company.title}</b>
                                    <div>
                                      {obj.company.fields.map((field, i) => (
                                        <small>
                                          <b>{field.title}</b>{" "}
                                          {i !== obj.company.fields.length - 1
                                            ? ","
                                            : "."}
                                        </small>
                                      ))}
                                    </div>
                                  </span>
                                }
                                {obj.user_type == 'advisor' && obj.fields && <div>
                                      {obj.fields.map((field, i) => (
                                        <small>
                                          <b>{field.title}</b>{" "}
                                          {i !== obj.fields.length - 1
                                            ? ","
                                            : "."}
                                        </small>
                                      ))}
                                    </div>}
                              </td>
                              <td>{obj.user_type}</td>
                              <td className="text-center">
                                <div className="notPrint">
                                  <button
                                    onClick={() => this.UpdateData(obj)}
                                    className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                                    title="Edit details"
                                    ng-click="UpdateData(role)"
                                  >
                                    <span className="svg-icon svg-icon-md">
                                      <svg viewBox="0 0 24 24" version="1.1">
                                        <g
                                          stroke="none"
                                          strokeWidth="1"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          ></rect>
                                          <path
                                            d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                            fill="#000000"
                                            fillRule="nonzero"
                                            transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                          ></path>
                                          <path
                                            d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                            fill="#000000"
                                            fillRule="nonzero"
                                            opacity="0.3"
                                          ></path>
                                        </g>
                                      </svg>
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                                    title="Delete"
                                    ng-click="Delete(role.srNo)"
                                    onClick={() =>
                                      this.handleDeleteALert(obj.id)
                                    }
                                  >
                                    <span className="svg-icon svg-icon-md">
                                      <svg viewBox="0 0 24 24" version="1.1">
                                        <g
                                          stroke="none"
                                          strokeWidth="1"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          ></rect>
                                          <path
                                            d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                            fill="#000000"
                                            fillRule="nonzero"
                                          ></path>
                                          <path
                                            d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                          ></path>
                                        </g>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h3>No data available.</h3>
                        )}
                      </tbody>
                      <tfoot className="notPrint">
                        <tr>
                          <td align="center" colSpan="6">
                            <div className="pull-right">
                              <button
                                id="btnFirst"
                                onClick={() => this.PaginateData(1)}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                First
                              </button>
                              <button
                                id="btnPrevious"
                                onClick={() => this.PreviousPageData()}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                Previous
                              </button>
                              {this.state.totalPages !== "0" ? (
                                <button
                                  id="bnt1"
                                  onClick={() => this.GetPageData("1")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  1
                                </button>
                              ) : null}
                              {this.state.totalPages > "1" ? (
                                <button
                                  id="bnt2"
                                  onClick={() => this.GetPageData("2")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  2
                                </button>
                              ) : null}
                              {this.state.totalPages > "2" ? (
                                <button
                                  id="bnt3"
                                  onClick={() => this.GetPageData("3")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  3
                                </button>
                              ) : null}
                              {this.state.totalPages > "3" ? (
                                <button
                                  id="bnt4"
                                  onClick={() => this.GetPageData("4")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  4
                                </button>
                              ) : null}
                              {this.state.totalPages > "4" ? (
                                <button
                                  id="bnt5"
                                  onClick={() => this.GetPageData("5")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  5
                                </button>
                              ) : null}
                              <button
                                id="btnNext"
                                onClick={() => this.NextPageData()}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                Next
                              </button>
                              <button
                                id="btnLast"
                                onClick={() =>
                                  this.PaginateData(this.state.totalPages)
                                }
                                className="btn btn-rounded btn-sm btn-outline-primary"
                              >
                                Last
                              </button>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <label className="btn btn-primary  mt-2 pulll-left">
                                  {this.state.pageNo * this.state.itemsPerPage -
                                    this.state.itemsPerPage}
                                  -
                                  {this.state.pageNo === this.state.totalPages
                                    ? this.state.totalItems
                                    : this.state.pageNo *
                                      this.state.itemsPerPage}
                                  / {this.state.totalItems} items
                                </label>
                              </div>
                              <div className="col-6">
                                <div className="row">
                                  <div className="col-3">
                                    <select
                                      className="form-control form-control-sm mt-3"
                                      name="itemsPerPage"
                                      onChange={this.onChange}
                                    >
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                      <option value="500">500</option>
                                    </select>
                                  </div>
                                  <div className="col-9">
                                    <button className="btn btn-primary mt-2 pull-right col-8">
                                      Page: {this.state.pageNo} /
                                      {this.state.totalPages}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </CardBody>
                </>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Users);