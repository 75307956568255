import React, { useState, useEffect } from "react";
import axios from "axios";

var URL = process.env.REACT_APP_API_URL;
export function Demo1Dashboard() {
  const [user_info, setUserInfo] = useState({});

  useEffect(() => {
    axios.get(`${URL}/user/my_data`).then((res) => {
      console.log(res.data);
      setUserInfo(res.data.user);
    });
  }, []);
  return (
    <>
      <div className="row">
        <div className="row col-9 m-0 mt-2 mb-2">
          <h2>
            Welcome{" "}
            <b>
              {user_info.first_name} {user_info.last_name}{" "}
            </b>
            !
          </h2>
        </div>
      </div>
    </>
  );
}
