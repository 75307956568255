import React, { Component } from "react";
class Image extends Component { 

  render() {
    return (
      <>
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-2">
          <img
            height="auto"
            width="100%"
            style={{ backgroundSize: "cover" }}
            src={`https://drive.google.com/thumbnail?authuser=0&sz=w320&id=${this.props?.file_id}`}
            alt="file"
          ></img>
          <div style={{ border: "1px solid black" }}>
            <div className="mt-1 mr-3">
              {[...new Array(8)].map((arr, index) => {
                return this.props?.stage?.includes(`-0${index + 1}`) ? (
                  this.props?.status === "done" ? (
                    <>
                      <label
                        className="ml-3"
                        style={{
                          width: "22px",
                          height: "22px",
                          borderRadius: "50%",
                          fontSize: "small",
                          fontStyle: "bold",
                          background: "green",
                          color: "#fff",
                          textAlign: "center",
                          paddingTop: "1.5px",
                        }}
                      >
                        {index + 1}
                      </label>
                      {index === 3 ? <br /> : null}
                    </>
                  ) : this.props?.status === "start" ? (
                    <>
                      <label
                        className="ml-3"
                        style={{
                          width: "22px",
                          height: "22px",
                          borderRadius: "50%",
                          fontSize: "small",
                          border: "1px solid",
                          borderColor: "green",
                          fontStyle: "bold",
                          color: "green",
                          textAlign: "center",
                          paddingTop: "1.5px",
                        }}
                      >
                        {index + 1}
                      </label>
                      {index === 3 ? <br /> : null}
                    </>
                  ) : (
                    <>
                      <label
                        className="ml-3"
                        style={{
                          width: "22px",
                          height: "22px",
                          borderRadius: "50%",
                          fontSize: "small",
                          border: "1px solid",
                          borderColor: "red",
                          fontStyle: "bold",
                          color: "red",
                          textAlign: "center",
                          paddingTop: "1.5px",
                        }}
                      >
                        {index + 1}
                      </label>
                      {index === 3 ? <br /> : null}
                    </>
                  )
                ) : index < Number(this.props?.stage?.charAt(4)) ? (
                  <>
                    <label
                      className="ml-3"
                      style={{
                        width: "22px",
                        height: "22px",
                        borderRadius: "50%",
                        fontSize: "small",
                        fontStyle: "bold",
                        background: "green",
                        color: "#fff",
                        textAlign: "center",
                        paddingTop: "1.5px",
                      }}
                    >
                      {index + 1}
                    </label>
                    {index === 3 ? <br /> : null}
                  </>
                ) : (
                  <>
                    <label
                      className="ml-3"
                      style={{
                        width: "22px",
                        height: "22px",
                        borderRadius: "50%",
                        border: "1px solid",
                        borderColor: "gray",
                        fontSize: "small",
                        fontStyle: "bold",
                        color: "gray",
                        textAlign: "center",
                        paddingTop: "1.5px",
                      }}
                    >
                      {index + 1}
                    </label>
                    {index === 3 ? <br /> : null}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Image;
