import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import Link from "../Link";
import {
  UserIcon,
  ChartBarIcon,
  UploadIcon,
  LoaderIcon,
  SignOutIcon,
  BarsIcon,
  GridIcon,
  HomeIcon,
  LayoutIcon,
} from "../../Icons";

export function AsideMenuList({ layoutProps }) {
  const [isAdmin, setAdmin] = useState(false);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (localStorage.getItem("user_type") === "admin") {
      setAdmin(true);
    }
  }, []);
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <Link
          link="/dashboard"
          name="Home"
          icon={
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
          }
          location={location}
        />
        {isAdmin && (
          <Link
            link="/users"
            name="Users"
            icon={<UserIcon />}
            location={location}
          />
        )}{" "}
        {isAdmin && (
          <Link
            link="/sizes"
            name="Sizes"
            icon={<ChartBarIcon />}
            location={location}
          />
        )}
        {isAdmin && (
          <Link
            link="/field-type"
            name="Field Type"
            icon={<BarsIcon />}
            location={location}
          />
        )}
        {isAdmin && (
          <Link
            link="/insects"
            name="Insects"
            icon={<GridIcon />}
            location={location}
          />
        )}
        {isAdmin && (
          <Link
            link="/company"
            name="Company"
            icon={<HomeIcon />}
            location={location}
          />
        )}{" "}
        {isAdmin && (
          <Link
            link="/field"
            name="Field"
            icon={<LayoutIcon />}
            location={location}
          />
        )}
        <Link
          link="/uploader"
          name="Uploader"
          icon={<UploadIcon />}
          location={location}
        />
        <Link
          link="/progress"
          name="Progress"
          icon={<LoaderIcon />}
          location={location}
        />
        <Link
          link="/logout"
          name="Signout"
          icon={<SignOutIcon />}
          location={location}
          onClick={() => {
            localStorage.removeItem("access_token");
            localStorage.removeItem("token");
            history.push("/logout");
          }}
        />
      </ul>
    </>
  );
}
