import * as React from "react";

const LoaderIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M12 4v2a6 6 0 1 1-5.24 3.075L5.014 8.1A8 8 0 1 0 12 4Z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.3}
      />
    </g>
  </svg>
);

export default LoaderIcon;
