import * as React from "react";

const ChartBarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M18.5 6A1.5 1.5 0 0 1 20 7.5v11a1.5 1.5 0 0 1-3 0v-11A1.5 1.5 0 0 1 18.5 6Zm-6 5a1.5 1.5 0 0 1 1.5 1.5v6a1.5 1.5 0 0 1-3 0v-6a1.5 1.5 0 0 1 1.5-1.5Zm-6 4A1.5 1.5 0 0 1 8 16.5v2a1.5 1.5 0 0 1-3 0v-2A1.5 1.5 0 0 1 6.5 15Z"
        fill="#000"
      />
    </g>
  </svg>
);

export default ChartBarIcon;
