import * as React from "react";

const UploadIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M2 13c0-.5.5-1 1-1s1 .5 1 1v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-5a1 1 0 0 1 2 0v5a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4v-5Z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.3}
      />
      <rect
        fill="#000"
        opacity={0.3}
        x={11}
        y={2}
        width={2}
        height={14}
        rx={1}
      />
      <path
        d="m12.036 3.378-4.329 4.33a1 1 0 0 1-1.414-1.415l5-5a1 1 0 0 1 1.376-.036l5 4.5a1 1 0 1 1-1.338 1.486l-4.295-3.865Z"
        fill="#000"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default UploadIcon;
