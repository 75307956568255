import React, { Component } from "react";
import { withRouter } from "react-router";
import SearchForTable from "../../Component/SearchForTable";
import $ from "jquery";
import axios from "axios";
import isEmpty from "../../../Validations/is-empty";
import { Form, Table } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import EditIcon from "../../../../_metronic/layout/components/Icons/EditIcon";
import DeleteIcon from "../../../../_metronic/layout/components/Icons/DeleteIcon";
const URL = process.env.REACT_APP_API_URL;

class FieldType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_title: "Add Type",
      button_title: "Add",
      action: "Save",
      name: "",
      data: [],
      id: 0,
      search: "",
      pageNo: 1,
      itemsPerPage: "20",
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
    this.PaginateData = this.PaginateData.bind(this);
    this.GetTotalPages = this.GetTotalPages.bind(this);
  }

  PaginateData(PageNo) {
    this.setState({ pageNo: PageNo + "" }, () => {
      axios
        .get(
          `${URL}/field-type?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
        )
        .then((res) => {
          this.setState(
            {
              data: res.data.users.rows,
              totalPages: Math.ceil(
                res.data.types.count / Number(this.state.itemsPerPage)
              ),
              totalItems: res.data.users.count,
            },
            () => {
              this.SetButtons();
            }
          );
        });
    });
  }

  async GetTotalPages() {
    await axios
      .get(
        `${URL}/field-type?page=1&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then((res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.types.rows,
            totalPages: Math.ceil(
              res.data.types.count / Number(this.state.itemsPerPage)
            ),
            totalItems: res.data.types.count,
          },
          () => {
            this.SetButtons();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  GetPaginatedData = () => {
    axios
      .get(
        `${URL}/field-type?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then((res) => {
        this.setState(
          {
            data: res.data.types.rows,
            totalPages: Math.ceil(
              res.data.types.count / Number(this.state.itemsPerPage)
            ),
            totalItems: res.data.types.count,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  GetPageData = (id) => {
    var data = {
      itemsPerPage: this.state.itemsPerPage,
      search: this.state.searchText,
      pageNo: this.state.pageNo,
    };
    if (id === "1") {
      this.setState({ pageNo: $("#bnt1").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "2") {
      this.setState({ pageNo: $("#bnt2").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "3") {
      this.setState({ pageNo: $("#bnt3").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "4") {
      this.setState({ pageNo: $("#bnt4").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "5") {
      this.setState({ pageNo: $("#bnt5").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    }
    console.log(data);
    axios
      .get(
        `${URL}/field-type?page=${id}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then(async (res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.types.rows,
            totalPages: Math.ceil(
              res.data.types.count / Number(this.state.itemsPerPage)
            ),
            totalItems: res.data.types.count,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  PreviousPageData = () => {
    if (this.state.pageNo !== 1) {
      this.setState({ pageNo: Number(this.state.pageNo) - 1 + "" }, () => {
        axios
          .get(
            `${URL}/field-type?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
          )
          .then(async (res) => {
            this.setState(
              {
                data: res.data.types.rows,
                totalPages: Math.ceil(
                  res.data.types.count / Number(this.state.itemsPerPage)
                ),
                totalItems: res.data.types.count,
              },
              () => {
                this.SetButtons();
              }
            );
          });
      });
    }
  };

  NextPageData = () => {
    this.setState({ pageNo: Number(this.state.pageNo) + 1 + "" }, () => {
      axios
        .get(
          `${URL}/field-type?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
        )
        .then(async (res) => {
          this.setState(
            {
              data: res.data.types.rows,
              totalPages: Math.ceil(
                res.data.types.count / Number(this.state.itemsPerPage)
              ),
              totalItems: res.data.types.count,
            },
            () => {
              this.SetButtons();
            }
          );
        });
    });
  };

  onSubmitValue = (val) => {
    console.log(val);
    this.setState({ search: val ? val : "" });
    axios
      .get(
        `${URL}/field-type?page=1&limit=${this.state.itemsPerPage}&search=${val}`
      )
      .then(async (res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.types.rows,
            totalPages: Math.ceil(
              res.data.types.count / Number(this.state.itemsPerPage)
            ),
            totalItems: res.data.types.count,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  SetButtons = () => {
    if (parseInt(this.state.pageNo) === 1) {
      $("#btnFirst").prop("disabled", true);
      $("#btnPrevious").prop("disabled", true);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      $("#bnt1").removeClass("btn-outline-primary");
      $("#bnt1").addClass("btn-primary");
      $("#bnt2").removeClass("btn-primary");
      $("#bnt2").addClass("btn-outline-primary");
      $("#bnt4").removeClass("btn-primary");
      $("#bnt4").addClass("btn-outline-primary");
      $("#bnt3").removeClass("btn-primary");
      $("#bnt3").addClass("btn-outline-primary");
      $("#bnt5").removeClass("btn-primary");
      $("#bnt5").addClass("btn-outline-primary");
      $("#bnt1").html("1");
      $("#bnt2").html("2");
      $("#bnt3").html("3");
      $("#bnt4").html("4");
      $("#bnt5").html("5");
      if (parseInt(this.state.totalPages) === 1) {
        $("#btnNext").prop("disabled", true);
        $("#btnLast").prop("disabled", true);
      }
    } else if (
      parseInt(this.state.pageNo) === parseInt(this.state.totalPages)
    ) {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", true);
      $("#btnLast").prop("disabled", true);
      if (parseInt(this.state.totalPages) < 6) {
        $("#bnt" + this.state.totalPages).removeClass("btn-outline-primary");
        $("#bnt" + this.state.totalPages).addClass("btn-primary");
        for (var i = parseInt(this.state.totalPages) - 1; i > 0; i--) {
          $("#bnt" + i).removeClass("btn-primary");
          $("#bnt" + i).addClass("btn-outline-primary");
        }
      } else {
        $("#bnt5").removeClass("btn-outline-primary");
        $("#bnt5").addClass("btn-primary");
        $("#bnt4").removeClass("btn-primary");
        $("#bnt4").addClass("btn-outline-primary");
        $("#bnt3").removeClass("btn-primary");
        $("#bnt3").addClass("btn-outline-primary");
        $("#bnt2").removeClass("btn-primary");
        $("#bnt2").addClass("btn-outline-primary");
        $("#bnt1").removeClass("btn-primary");
        $("#bnt1").addClass("btn-outline-primary");

        $("#bnt1").html("" + (parseInt(this.state.pageNo) - 4));
        $("#bnt2").html("" + (parseInt(this.state.pageNo) - 3));
        $("#bnt3").html("" + (parseInt(this.state.pageNo) - 2));
        $("#bnt4").html("" + (parseInt(this.state.pageNo) - 1));
        $("#bnt5").html("" + parseInt(this.state.pageNo));
      }
    } else {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      if (parseInt(this.state.totalPages) < 6) {
        for (var j = parseInt(this.state.totalPages); j > 0; j--) {
          $("#bnt" + j).removeClass("btn-primary");
          $("#bnt" + j).addClass("btn-outline-primary");
        }
        $("#bnt" + this.state.pageNo).removeClass("btn-outline-primary");
        $("#bnt" + this.state.pageNo).addClass("btn-primary");
      } else {
        if (parseInt(this.state.pageNo) === 2) {
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-primary");
          $("#bnt3").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-outline-primary");
          $("#bnt2").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else if (parseInt(this.state.pageNo) === 3) {
          $("#bnt1").html("1");
          $("#bnt2").html("2");
          $("#bnt3").html("3");
          $("#bnt4").html("4");
          $("#bnt5").html("5");
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-primary");
          $("#bnt2").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-outline-primary");
          $("#bnt3").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else {
          if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            2
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt4").removeClass("btn-primary");
            $("#bnt4").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt3").removeClass("btn-outline-primary");
            $("#bnt3").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) ===
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 3));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt3").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt4").html("" + parseInt(this.state.pageNo));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt3").removeClass("btn-primary");
            $("#bnt3").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt4").removeClass("btn-outline-primary");
            $("#bnt4").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          }
        }
      }
    }
  };

  Search = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.GetTotalPages();
    }
  };

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  handleDeleteALert = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this?</p>
            <button
              className="btn btn-danger"
              onClick={onClose}
              style={{ marginRight: 10 }}
            >
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.Delete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  Delete = (id) => {
    toast.success("Deleting type...");
    axios.delete(`${URL}/field-type/${id}`).then(async (res) => {
      toast.success(res.data.message);
      this.setState(
        {
          id: "",
          name: "",
          action: "Save",
          modal_title: "Add Type",
          button_title: "Add",
        },
        () => {
          this.GetTotalPages();
        }
      );
    });
  };

  UpdateData = (obj) => {
    this.setState({
      id: obj.id,
      name: obj.name,
      modal_title: "Update Type",
      button_title: "Update",
      action: "Update",
    });
  };

  AddData = () => {
    this.setState({
      id: 0,
      name: "",
      modal_title: "Add Type",
      button_title: "Add",
      action: "Save",
    });
  };
  componentWillMount() {
    this.GetTotalPages();
  }

  Validate = () => {
    if (isEmpty(this.state.name)) {
      toast.error("Please enter field type!");
      return false;
    } else {
      return true;
    }
  };

  async Save() {
    let isValid = await this.Validate();
    if (isValid) {
      var obj = {
        name: this.state.name,
      };
      if (this.state.action === "Save") {
        toast.success("Creating type...!");
        axios.post(`${URL}/field-type`, obj).then(async (res) => {
          toast.success("Field Type Created Successfully!");
          this.AddData();
          this.GetTotalPages();
        });
      } else {
        toast.success("Updating type...");

        axios
          .put(`${URL}/field-type/${this.state.id}`, obj)
          .then(async (res) => {
            toast.success("Field Type Updated Successfully!");
            this.AddData();
            this.GetTotalPages();
          });
      }
    }
  }

  onChange(e) {
    var name = e.target.name;
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state.itemsPerPage);
      if (name === "itemsPerPage") {
        this.GetTotalPages();
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <i
            className="flaticon-user mr-3"
            style={{
              fontSize: "35px",
              color: "#3F4254",
              fontWeight: "900",
            }}
          ></i>
          <h3 style={{ marginBottom: "1.2%" }}>Field Types</h3>
        </div>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            ></div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <Card>
                <CardHeader title={this.state.modal_title}></CardHeader>
                <CardBody>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <div className="input-group ">
                      <input
                        onKeyPress={this.CheckKey}
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="Enter field type"
                        value={this.state.name}
                        name="name"
                        onChange={this.onChange}
                      ></input>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i
                            className="fa fa-user"
                            style={{ fontSize: "13px" }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </Form.Group>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    {this.state.button_title}
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => this.AddData()}
                    name="cancel"
                    style={{ float: "left" }}
                  >
                    Cancel
                  </button>
                </CardBody>
              </Card>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-8">
              <Card>
                <CardHeader title="Field Types">
                  <CardHeaderToolbar>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.AddData()}
                      name="add_button"
                    >
                      Add New Type
                    </button>
                  </CardHeaderToolbar>
                </CardHeader>
                <>
                  <div>
                    <SearchForTable onSubmitValue={this.onSubmitValue} />{" "}
                  </div>
                  <CardBody ref={(el) => (this.componentRef = el)}>
                    <div className="row d-none print">
                      <div className="col-sm-12">
                        <span>
                          <h2 className="text-center">Types</h2>
                        </span>
                      </div>
                    </div>

                    <Table responsive>
                      <thead>
                        <tr>
                          <th width="10%">
                            <span style={{ display: "flex" }}>Sr#</span>
                          </th>
                          <th width="70%">
                            <span style={{ display: "flex" }}>Type</span>
                          </th>
                          <th width="20%" className="text-center">
                            Events
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data ? (
                          this.state.data.map((obj, k) => (
                            <tr>
                              <td>
                                {this.state.itemsPerPage *
                                  (this.state.pageNo - 1) +
                                  k +
                                  1}
                              </td>
                              <td>{obj.name}</td>
                              <td className="text-center">
                                <div className="notPrint">
                                  <button
                                    onClick={() => this.UpdateData(obj)}
                                    className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                                    title="Edit details"
                                    ng-click="UpdateData(role)"
                                  >
                                    <span className="svg-icon svg-icon-md">
                                      <EditIcon />
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                                    title="Delete"
                                    ng-click="Delete(role.srNo)"
                                    onClick={() =>
                                      this.handleDeleteALert(obj.id)
                                    }
                                  >
                                    <span className="svg-icon svg-icon-md">
                                      <DeleteIcon />
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h3>No data available.</h3>
                        )}
                      </tbody>
                      <tfoot className="notPrint">
                        <tr>
                          <td align="center" colSpan="6">
                            <div className="pull-right">
                              <button
                                id="btnFirst"
                                onClick={() => this.PaginateData(1)}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                First
                              </button>
                              <button
                                id="btnPrevious"
                                onClick={() => this.PreviousPageData()}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                Previous
                              </button>
                              {this.state.totalPages !== "0" ? (
                                <button
                                  id="bnt1"
                                  onClick={() => this.GetPageData("1")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  1
                                </button>
                              ) : null}
                              {this.state.totalPages > "1" ? (
                                <button
                                  id="bnt2"
                                  onClick={() => this.GetPageData("2")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  2
                                </button>
                              ) : null}
                              {this.state.totalPages > "2" ? (
                                <button
                                  id="bnt3"
                                  onClick={() => this.GetPageData("3")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  3
                                </button>
                              ) : null}
                              {this.state.totalPages > "3" ? (
                                <button
                                  id="bnt4"
                                  onClick={() => this.GetPageData("4")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  4
                                </button>
                              ) : null}
                              {this.state.totalPages > "4" ? (
                                <button
                                  id="bnt5"
                                  onClick={() => this.GetPageData("5")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  5
                                </button>
                              ) : null}
                              <button
                                id="btnNext"
                                onClick={() => this.NextPageData()}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                Next
                              </button>
                              <button
                                id="btnLast"
                                onClick={() =>
                                  this.PaginateData(this.state.totalPages)
                                }
                                className="btn btn-rounded btn-sm btn-outline-primary"
                              >
                                Last
                              </button>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <label className="btn btn-primary  mt-2 pulll-left">
                                  {this.state.pageNo * this.state.itemsPerPage -
                                    this.state.itemsPerPage}
                                  -
                                  {this.state.pageNo === this.state.totalPages
                                    ? this.state.totalItems
                                    : this.state.pageNo *
                                      this.state.itemsPerPage}
                                  / {this.state.totalItems} items
                                </label>
                              </div>
                              <div className="col-6">
                                <div className="row">
                                  <div className="col-3">
                                    <select
                                      className="form-control form-control-sm mt-3"
                                      name="itemsPerPage"
                                      onChange={this.onChange}
                                    >
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                      <option value="500">500</option>
                                    </select>
                                  </div>
                                  <div className="col-9">
                                    <button className="btn btn-primary mt-2 pull-right col-8">
                                      Page: {this.state.pageNo} /
                                      {this.state.totalPages}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </CardBody>
                </>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(FieldType);
