import HomeIcon from "./HomeIcon";
import BarsIcon from "./BarsIcon";
import ChartBarIcon from "./ChartBarsIcon";
import DeleteIcon from "./DeleteIcon";
import EditIcon from "./EditIcon";
import GridIcon from "./GridIcon";
import LoaderIcon from "./LoaderIcon";
import SignOutIcon from "./SignOutIcon";
import UploadIcon from "./UploadIcon";
import UserIcon from "./UserIcon";
import LayoutIcon from "./LayoutIcon";
export {
  HomeIcon,
  BarsIcon,
  ChartBarIcon,
  DeleteIcon,
  EditIcon,
  GridIcon,
  LoaderIcon,
  SignOutIcon,
  UploadIcon,
  UserIcon,
  LayoutIcon,
};
