import React, { Component } from "react";
import { withRouter } from "react-router";
import SearchForTable from "../../Component/SearchForTable";
import $ from "jquery";
import axios from "axios";
import isEmpty from "../../../Validations/is-empty";
import { Form, Table } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import {
  EditIcon,
  DeleteIcon,
} from "../../../../_metronic/layout/components/Icons";
import Select from "react-select";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import CreateDate from "../../../Utils/CreateDate";
const URL = process.env.REACT_APP_API_URL;
var date = CreateDate(new Date().toString());
class Field extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_title: "Add Field",
      button_title: "Add",
      action: "Save",
      title: "",
      ui_field_type_id: "0",
      company_id: "0",
      city: "",
      district: "",
      province: "",
      uid: "",
      gdrive_uid: "",
      gdrive_resync_uuid: "",
      command: "-m resnet50_pytorch",
      options: "--size 1280 -m yolov5l -t 12000",
      save_tile_inf_files: false,
      save_pre_nms_image_infs: false,
      save_post_nms_image_infs: false,
      start_date_: date,
      end_date_: date,
      x_size: 1024,
      y_size: 1024,
      x_overlap: 100,
      y_overlap: 100,
      trap_face_eol_days: 20,
      start_date: date,
      end_date: date,
      data: [],
      id: 0,
      search: "",
      pageNo: 1,
      itemsPerPage: "20",
      types: [],
      companies: [],
      allTypes: [],
      allCompanies: [],
      show_modal: false,
      type: "0",
      company: "0",
      conf_sectors: 0,
      conf_tunnels: 0,
      conf_rows: 0,
      conf_cages: 0,
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
    this.PaginateData = this.PaginateData.bind(this);
    this.GetTotalPages = this.GetTotalPages.bind(this);
  }

  PaginateData(PageNo) {
    this.setState({ pageNo: PageNo + "" }, () => {
      axios
        .get(
          `${URL}/field?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
        )
        .then((res) => {
          this.setState(
            {
              data: res.data.fields.rows,
              totalPages: Math.ceil(
                res.data.fields.count / Number(this.state.itemsPerPage)
              ),
              totalItems: res.data.fields.count,
            },
            () => {
              this.SetButtons();
            }
          );
        });
    });
  }

  async GetTotalPages() {
    await axios
      .get(
        `${URL}/field?page=1&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then((res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.fields.rows,
            totalPages: Math.ceil(
              res.data.fields.count / Number(this.state.itemsPerPage)
            ),
            totalItems: res.data.fields.count,
          },
          () => {
            this.SetButtons();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  GetPaginatedData = () => {
    axios
      .get(
        `${URL}/field?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then((res) => {
        this.setState(
          {
            data: res.data.fields.rows,
            totalPages: Math.ceil(
              res.data.fields.count / Number(this.state.itemsPerPage)
            ),
            totalItems: res.data.fields.count,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  GetPageData = (id) => {
    var data = {
      itemsPerPage: this.state.itemsPerPage,
      search: this.state.searchText,
      pageNo: this.state.pageNo,
    };
    if (id === "1") {
      this.setState({ pageNo: $("#bnt1").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "2") {
      this.setState({ pageNo: $("#bnt2").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "3") {
      this.setState({ pageNo: $("#bnt3").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "4") {
      this.setState({ pageNo: $("#bnt4").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "5") {
      this.setState({ pageNo: $("#bnt5").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    }
    console.log(data);
    axios
      .get(
        `${URL}/field?page=${id}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then(async (res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.fields.rows,
            totalPages: Math.ceil(
              res.data.fields.count / Number(this.state.itemsPerPage)
            ),
            totalItems: res.data.fields.count,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  PreviousPageData = () => {
    if (this.state.pageNo !== 1) {
      this.setState({ pageNo: Number(this.state.pageNo) - 1 + "" }, () => {
        axios
          .get(
            `${URL}/field?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
          )
          .then(async (res) => {
            this.setState(
              {
                data: res.data.fields.rows,
                totalPages: Math.ceil(
                  res.data.fields.count / Number(this.state.itemsPerPage)
                ),
                totalItems: res.data.fields.count,
              },
              () => {
                this.SetButtons();
              }
            );
          });
      });
    }
  };

  NextPageData = () => {
    this.setState({ pageNo: Number(this.state.pageNo) + 1 + "" }, () => {
      axios
        .get(
          `${URL}/field?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
        )
        .then(async (res) => {
          this.setState(
            {
              data: res.data.fields.rows,
              totalPages: Math.ceil(
                res.data.fields.count / Number(this.state.itemsPerPage)
              ),
              totalItems: res.data.fields.count,
            },
            () => {
              this.SetButtons();
            }
          );
        });
    });
  };

  onSubmitValue = (val) => {
    this.setState({ search: val ? val : "" });
    axios
      .get(
        `${URL}/field?page=1&limit=${this.state.itemsPerPage}&search=${val}&company=${this.state.company}&type=${this.state.type}`
      )
      .then(async (res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.fields.rows,
            totalPages: Math.ceil(
              res.data.fields.count / Number(this.state.itemsPerPage)
            ),
            totalItems: res.data.fields.count,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  SetButtons = () => {
    if (parseInt(this.state.pageNo) === 1) {
      $("#btnFirst").prop("disabled", true);
      $("#btnPrevious").prop("disabled", true);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      $("#bnt1").removeClass("btn-outline-primary");
      $("#bnt1").addClass("btn-primary");
      $("#bnt2").removeClass("btn-primary");
      $("#bnt2").addClass("btn-outline-primary");
      $("#bnt4").removeClass("btn-primary");
      $("#bnt4").addClass("btn-outline-primary");
      $("#bnt3").removeClass("btn-primary");
      $("#bnt3").addClass("btn-outline-primary");
      $("#bnt5").removeClass("btn-primary");
      $("#bnt5").addClass("btn-outline-primary");
      $("#bnt1").html("1");
      $("#bnt2").html("2");
      $("#bnt3").html("3");
      $("#bnt4").html("4");
      $("#bnt5").html("5");
      if (parseInt(this.state.totalPages) === 1) {
        $("#btnNext").prop("disabled", true);
        $("#btnLast").prop("disabled", true);
      }
    } else if (
      parseInt(this.state.pageNo) === parseInt(this.state.totalPages)
    ) {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", true);
      $("#btnLast").prop("disabled", true);
      if (parseInt(this.state.totalPages) < 6) {
        $("#bnt" + this.state.totalPages).removeClass("btn-outline-primary");
        $("#bnt" + this.state.totalPages).addClass("btn-primary");
        for (var i = parseInt(this.state.totalPages) - 1; i > 0; i--) {
          $("#bnt" + i).removeClass("btn-primary");
          $("#bnt" + i).addClass("btn-outline-primary");
        }
      } else {
        $("#bnt5").removeClass("btn-outline-primary");
        $("#bnt5").addClass("btn-primary");
        $("#bnt4").removeClass("btn-primary");
        $("#bnt4").addClass("btn-outline-primary");
        $("#bnt3").removeClass("btn-primary");
        $("#bnt3").addClass("btn-outline-primary");
        $("#bnt2").removeClass("btn-primary");
        $("#bnt2").addClass("btn-outline-primary");
        $("#bnt1").removeClass("btn-primary");
        $("#bnt1").addClass("btn-outline-primary");

        $("#bnt1").html("" + (parseInt(this.state.pageNo) - 4));
        $("#bnt2").html("" + (parseInt(this.state.pageNo) - 3));
        $("#bnt3").html("" + (parseInt(this.state.pageNo) - 2));
        $("#bnt4").html("" + (parseInt(this.state.pageNo) - 1));
        $("#bnt5").html("" + parseInt(this.state.pageNo));
      }
    } else {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      if (parseInt(this.state.totalPages) < 6) {
        for (var j = parseInt(this.state.totalPages); j > 0; j--) {
          $("#bnt" + j).removeClass("btn-primary");
          $("#bnt" + j).addClass("btn-outline-primary");
        }
        $("#bnt" + this.state.pageNo).removeClass("btn-outline-primary");
        $("#bnt" + this.state.pageNo).addClass("btn-primary");
      } else {
        if (parseInt(this.state.pageNo) === 2) {
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-primary");
          $("#bnt3").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-outline-primary");
          $("#bnt2").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else if (parseInt(this.state.pageNo) === 3) {
          $("#bnt1").html("1");
          $("#bnt2").html("2");
          $("#bnt3").html("3");
          $("#bnt4").html("4");
          $("#bnt5").html("5");
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-primary");
          $("#bnt2").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-outline-primary");
          $("#bnt3").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else {
          if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            2
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt4").removeClass("btn-primary");
            $("#bnt4").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt3").removeClass("btn-outline-primary");
            $("#bnt3").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) ===
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 3));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt3").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt4").html("" + parseInt(this.state.pageNo));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt3").removeClass("btn-primary");
            $("#bnt3").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt4").removeClass("btn-outline-primary");
            $("#bnt4").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          }
        }
      }
    }
  };

  Search = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.GetTotalPages();
    }
  };

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  handleDeleteALert = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this?</p>
            <button
              className="btn btn-danger"
              onClick={onClose}
              style={{ marginRight: 10 }}
            >
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.Delete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  Delete = (id) => {
    toast.success("Deleting field...");
    axios.delete(`${URL}/field/${id}`).then(async (res) => {
      toast.success(res.data.message);
      this.setState(
        {
          id: "",
          title: "",
          ui_field_type_id: "0",
          company_id: "0",
          city: "",
          district: "",
          province: "",
          uid: "",
          gdrive_uid: "",
          gdrive_resync_uuid: "",
          action: "Save",
          modal_title: "Add Field",
          button_title: "Add",
          conf_sectors: 0,
          conf_tunnels: 0,
          conf_rows: 0,
          conf_cages: 0,
        },
        () => {
          this.GetTotalPages();
        }
      );
    });
  };

  UpdateData = (obj) => {
    this.setState({
      id: obj.id,
      title: obj.title,
      ui_field_type_id: obj.ui_field_type_id + "",
      company_id: obj.company_id + "",
      city: obj.city,
      district: obj.district,
      province: obj.province,
      uid: obj.uid,
      gdrive_uid: obj.gdrive_uid,
      gdrive_resync_uuid: obj.gdrive_resync_uuid,
      command: obj.field_classifier_command?.command,
      options: obj.field_inf_cmd?.options,
      start_date_: CreateDate(obj.field_tile_config?.start_date),
      end_date_: CreateDate(obj.field_tile_config?.end_date),
      x_size: obj.field_tile_config?.x_size,
      y_size: obj.field_tile_config?.y_size,
      x_overlap: obj.field_tile_config?.x_overlap,
      y_overlap: obj.field_tile_config?.y_overlap,
      trap_face_eol_days: obj.field_config?.trap_face_eol_days,
      start_date: CreateDate(obj.field_config?.start_date),
      end_date: CreateDate(obj.field_config?.end_date),
      save_tile_inf_files: obj.field_config?.save_tile_inf_files
        ? obj.field_config?.save_tile_inf_files
        : false,
      save_pre_nms_image_infs: obj.field_config?.save_pre_nms_image_infs
        ? obj.field_config?.save_tile_inf_files
        : false,
      save_post_nms_image_infs: obj.field_config?.save_post_nms_image_infs
        ? obj.field_config?.save_tile_inf_files
        : false,
      conf_sectors: obj.conf_sectors,
      conf_tunnels: obj.conf_tunnels,
      conf_rows: obj.conf_rows,
      conf_cages: obj.conf_cages,
      modal_title: "Update Field",
      button_title: "Update",
      action: "Update",
      show_modal: true,
    });
  };

  AddData = () => {
    this.setState({
      id: 0,
      title: "",
      ui_field_type_id: "0",
      company_id: "0",
      city: "",
      district: "",
      province: "",
      uid: "",
      gdrive_uid: "",
      gdrive_resync_uuid: "",
      command: "-m resnet50_pytorch",
      options: "--size 1280 -m yolov5l -t 12000",
      start_date_: date,
      end_date_: date,
      x_size: 1024,
      y_size: 1024,
      x_overlap: 100,
      y_overlap: 100,
      trap_face_eol_days: 20,
      start_date: date,
      end_date: date,
      save_tile_inf_files: false,
      save_pre_nms_image_infs: false,
      save_post_nms_image_infs: false,
      modal_title: "Add Field",
      button_title: "Add",
      action: "Save",
      show_modal: true,
      conf_sectors: 0,
      conf_tunnels: 0,
      conf_rows: 0,
      conf_cages: 0,
    });
  };

  componentWillMount() {
    this.GetTotalPages();
    this.GetTypes();
    this.GetCompanies();
  }

  TypeChanges = (val) => {
    this.setState({ ui_field_type_id: val.id });
  };

  CompanyChanges = (val) => {
    this.setState({ company_id: val.id });
  };

  AllTypeChanges = (val) => {
    this.setState({ type: val.id });
  };

  AllCompanyChanges = (val) => {
    this.setState({ company: val.id });
  };

  Validate = () => {
    if (isEmpty(this.state.uid)) {
      toast.error("Please enter field id!");
      return false;
    } else if (isEmpty(this.state.title)) {
      toast.error("Please enter field title!");
      return false;
    } else if (isEmpty(this.state.province)) {
      toast.error("Please enter field province!");
      return false;
    } else if (isEmpty(this.state.city)) {
      toast.error("Please enter field city!");
      return false;
    } else if (isEmpty(this.state.district)) {
      toast.error("Please enter field district!");
      return false;
    } else if (this.state.ui_field_type_id === "0") {
      toast.error("Please select field type!");
      return false;
    } else if (this.state.company_id === "0") {
      toast.error("Please select company!");
      return false;
    } else if (isEmpty(this.state.start_date)) {
      toast.error("Please select start date for config!");
      return false;
    } else if (isEmpty(this.state.end_date)) {
      toast.error("Please select end date for config!");
      return false;
    } else if (isEmpty(this.state.trap_face_eol_days)) {
      toast.error("Please enter EOL Days!");
      return false;
    } else if (isEmpty(this.state.command)) {
      toast.error("Please enter command!");
      return false;
    } else if (isEmpty(this.state.options)) {
      toast.error("Please enter option!");
      return false;
    } else if (isEmpty(this.state.x_size)) {
      toast.error("Please enter X Size!");
      return false;
    } else if (isEmpty(this.state.y_size)) {
      toast.error("Please enter Y size!");
      return false;
    } else if (isEmpty(this.state.x_overlap)) {
      toast.error("Please enter X Overlap!");
      return false;
    } else if (isEmpty(this.state.y_overlap)) {
      toast.error("Please enter Y Overlap!");
      return false;
    } else if (isEmpty(this.state.start_date_)) {
      toast.error("Please select start date for tile config!");
      return false;
    } else if (isEmpty(this.state.end_date_)) {
      toast.error("Please select end date for tile config!");
      return false;
    } else {
      return true;
    }
  };

  async Save() {
    let isValid = await this.Validate();
    if (isValid) {
      var obj = {
        title: this.state.title,
        ui_field_type_id: this.state.ui_field_type_id,
        company_id: this.state.company_id,
        city: this.state.city,
        district: this.state.district,
        province: this.state.province,
        uid: this.state.uid,
        gdrive_uid: this.state.gdrive_uid,
        gdrive_resync_uuid: this.state.gdrive_resync_uuid,
        command: this.state.command,
        options: this.state.options,
        start_date_: this.state.start_date_,
        end_date_: this.state.end_date_,
        conf_sectors: this.state.conf_sectors,
        conf_tunnels: this.state.conf_tunnels,
        conf_rows: this.state.conf_rows,
        conf_cages: this.state.conf_cages,
        x_size: isEmpty(this.state.x_size) ? 0 : this.state.x_size,
        y_size: isEmpty(this.state.y_size) ? 0 : this.state.y_size,
        x_overlap: isEmpty(this.state.x_overlap) ? 0 : this.state.x_overlap,
        y_overlap: isEmpty(this.state.y_overlap) ? 0 : this.state.y_overlap,
        trap_face_eol_days: isEmpty(this.state.trap_face_eol_days)
          ? 0
          : this.state.trap_face_eol_days,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        save_tile_inf_files: isEmpty(this.state.save_tile_inf_files)
          ? false
          : this.state.save_tile_inf_files,
        save_pre_nms_image_infs: isEmpty(this.state.save_pre_nms_image_infs)
          ? false
          : this.state.save_pre_nms_image_infs,
        save_post_nms_image_infs: isEmpty(this.state.save_post_nms_image_infs)
          ? false
          : this.state.save_post_nms_image_infs,
      };
      if (this.state.action === "Save") {
        toast.success("Creating field...!");
        axios.post(`${URL}/field`, obj).then(async (res) => {
          toast.success("Field Created Successfully!");
          this.closeModal();
          this.GetTotalPages();
        });
      } else {
        toast.success("Updating field...");

        axios
          .put(`${URL}/field/${this.state.id}`, obj)
          .then(async (res) => {
            toast.success("Field Updated Successfully!");
            this.closeModal();
            this.GetTotalPages();
          });
      }
    }
  }

  onChange(e) {
    var name = e.target.name;
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (name === "itemsPerPage") {
        this.GetTotalPages();
      }
    });
  }

  GetTypes = () => {
    axios.get(`${URL}/field-type?page=1&limit=1000`).then(async (res) => {
      var newCategories = [{ id: "0", name: "-- Select Type --" }];
      var data = res.data?.types?.rows;
      for (var i = 0; i < data.length; i++) {
        newCategories.push({
          id: data[i].id + "",
          name: data[i].name,
        });
      }
      await this.setState({
        types: newCategories,
      });
      newCategories = [{ id: "0", name: "All Field Types" }];
      data = res.data?.types?.rows;
      for (i = 0; i < data.length; i++) {
        newCategories.push({
          id: data[i].id + "",
          name: data[i].name,
        });
      }
      await this.setState({
        allTypes: newCategories,
      });
    });
  };

  GetCompanies = () => {
    axios.get(`${URL}/company?page=1&limit=1000`).then(async (res) => {
      var newCategories = [{ id: "0", name: "-- Select Company --" }];
      var data = res.data?.companies?.rows;
      for (var i = 0; i < data.length; i++) {
        newCategories.push({
          id: data[i].id + "",
          name: data[i].title,
        });
      }
      await this.setState({
        companies: newCategories,
      });
      newCategories = [{ id: "0", name: "All Companies" }];
      data = res.data?.companies?.rows;
      for (i = 0; i < data.length; i++) {
        newCategories.push({
          id: data[i].id + "",
          name: data[i].title,
        });
      }
      await this.setState({
        allCompanies: newCategories,
      });
    });
  };

  closeModal = () => {
    this.AddData();
    this.setState({ show_modal: false });
  };

  hanldeCheck = (name) => {
    console.log(name + ":" + this.state[name]);
    var val = this.state[name];
    this.setState({ [name]: !val });
  };

  render() {
    const customStyles = {
      control: (base) => ({
        ...base,
        height: 33,
        minHeight: 33,
        border: "1px solid #E4E6EF",
        borderRadius: "3px",
        fontSize: 10.5,
        fontWeight: 400,
        paddingTop: -25,
      }),
    };
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          <i
            className="flaticon-user mr-3"
            style={{
              fontSize: "35px",
              color: "#3F4254",
              fontWeight: "900",
            }}
          ></i>
          <h3 style={{ marginBottom: "1.2%" }}>Field</h3>
        </div>

        <div className="page-content">
          <div className="row">
            <div className="col-12">
              <Dialog
                fullWidth
                maxWidth="lg"
                open={this.state.show_modal}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  {this.state.modal_title}
                </DialogTitle>
                <DialogContent>
                  <h4>Data</h4>
                  <div className="row">
                    <Form.Group className="col-lg-3 col-md-4 col-sm-12">
                      <Form.Label>ID</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="Enter field uid"
                          value={this.state.uid}
                          name="uid"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-3 col-md-4 col-sm-12">
                      <Form.Label>Title</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="Enter field title"
                          value={this.state.title}
                          name="title"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-3 col-md-4 col-sm-12">
                      <Form.Label>Province</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="Enter field province"
                          value={this.state.province}
                          name="province"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-3 col-md-4 col-sm-12">
                      <Form.Label>City</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="Enter field city"
                          value={this.state.city}
                          name="city"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-3 col-md-4 col-sm-12">
                      <Form.Label>District</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="Enter field district"
                          value={this.state.district}
                          name="district"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-3 col-md-4 col-sm-12">
                      <Form.Label>G Drive Id</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="Enter field G drive id"
                          value={this.state.gdrive_uid}
                          name="gdrive_uid"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-3 col-md-4 col-sm-12">
                      <Form.Label>G Drive Resync Id</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="Enter field G drive resync id"
                          value={this.state.gdrive_resync_uuid}
                          name="gdrive_resync_uuid"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-3 col-md-4 col-sm-12">
                      <Form.Label>Field Type</Form.Label>
                      <Select
                        styles={customStyles}
                        name="type_id"
                        options={this.state.types}
                        onChange={this.TypeChanges}
                        value={this.state.types.filter(
                          ({ id }) => id === this.state.ui_field_type_id
                        )}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </Form.Group>
                    <Form.Group className="col-lg-3 col-md-4 col-sm-12">
                      <Form.Label>Company</Form.Label>
                      <Select
                        styles={customStyles}
                        name="company_id"
                        options={this.state.companies}
                        onChange={this.CompanyChanges}
                        value={this.state.companies.filter(
                          ({ id }) => id === this.state.company_id
                        )}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </Form.Group>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <h4>Config</h4>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <Form.Group className="col-lg-4 col-md-4 col-sm-12">
                              <Form.Label>Start Date</Form.Label>
                              <div className="input-group ">
                                <input
                                  onKeyPress={this.CheckKey}
                                  className="form-control form-control-sm"
                                  type="date"
                                  placeholder="Enter start date"
                                  value={this.state.start_date_}
                                  name="start_date_"
                                  onChange={this.onChange}
                                ></input>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i
                                      className="fa fa-user"
                                      style={{ fontSize: "13px" }}
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group className="col-lg-4 col-md-4 col-sm-12">
                              <Form.Label>End Date</Form.Label>
                              <div className="input-group ">
                                <input
                                  onKeyPress={this.CheckKey}
                                  className="form-control form-control-sm"
                                  type="date"
                                  placeholder="Enter end date"
                                  value={this.state.end_date_}
                                  name="end_date_"
                                  onChange={this.onChange}
                                ></input>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i
                                      className="fa fa-user"
                                      style={{ fontSize: "13px" }}
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group className="col-lg-4 col-md-4 col-sm-12">
                              <Form.Label>EOL Days</Form.Label>
                              <div className="input-group ">
                                <input
                                  onKeyPress={this.CheckKey}
                                  className="form-control form-control-sm"
                                  type="number"
                                  placeholder="Enter days"
                                  value={this.state.trap_face_eol_days}
                                  name="trap_face_eol_days"
                                  onChange={this.onChange}
                                ></input>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i
                                      className="fa fa-user"
                                      style={{ fontSize: "13px" }}
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row">
                            <Form.Group className="col-lg-4 col-md-4 col-sm-12">
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  name="save_tile_inf_files"
                                  checked={this.state.save_tile_inf_files}
                                  onChange={() =>
                                    this.hanldeCheck("save_tile_inf_files")
                                  }
                                />
                                <span></span>
                                <label className="ml-2 mt-2">
                                  <small>
                                    <b>Save Tile Inf Files</b>
                                  </small>
                                </label>
                              </label>
                            </Form.Group>{" "}
                            <Form.Group className="col-lg-4 col-md-4 col-sm-12">
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  name="save_pre_nms_image_infs"
                                  checked={this.state.save_pre_nms_image_infs}
                                  onChange={() =>
                                    this.hanldeCheck("save_pre_nms_image_infs")
                                  }
                                />
                                <span></span>
                                <label className="ml-2 mt-2">
                                  <small>
                                    <b>Save Pre NMS Image Inf</b>
                                  </small>
                                </label>
                              </label>
                            </Form.Group>
                            <Form.Group className="col-lg-4 col-md-4 col-sm-12">
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  name="save_post_nms_image_infs"
                                  checked={this.state.save_post_nms_image_infs}
                                  onChange={() =>
                                    this.hanldeCheck("save_post_nms_image_infs")
                                  }
                                />
                                <span></span>
                                <label className="ml-2 mt-2">
                                  <small>
                                    <b>Save Post NMS Image Inf</b>
                                  </small>
                                </label>
                              </label>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <h4>Classifier Command</h4>
                      <Form.Group>
                        <Form.Label>Command</Form.Label>
                        <div className="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Enter classifier command"
                            value={this.state.command}
                            name="command"
                            onChange={this.onChange}
                          ></input>
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                className="fa fa-user"
                                style={{ fontSize: "13px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <h4>Info Command</h4>
                      <Form.Group>
                        <Form.Label>Option</Form.Label>
                        <div className="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Enter info command option"
                            value={this.state.options}
                            name="options"
                            onChange={this.onChange}
                          ></input>
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                className="fa fa-user"
                                style={{ fontSize: "13px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <h4>Tile Config</h4>
                  <div className="row">
                    <Form.Group className="col-lg-2 col-md-4 col-sm-12">
                      <Form.Label>X Size</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="number"
                          placeholder="Enter x size"
                          value={this.state.x_size}
                          name="x_size"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-2 col-md-4 col-sm-12">
                      <Form.Label>Y Size</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="number"
                          placeholder="Enter y size"
                          value={this.state.y_size}
                          name="y_size"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-2 col-md-4 col-sm-12">
                      <Form.Label>X Overlap</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="number"
                          placeholder="Enter x overlap"
                          value={this.state.x_overlap}
                          name="x_overlap"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-2 col-md-4 col-sm-12">
                      <Form.Label>Y Overlap</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="number"
                          placeholder="Enter y overlap"
                          value={this.state.y_overlap}
                          name="y_overlap"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-2 col-md-4 col-sm-12">
                      <Form.Label>Start Date</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="date"
                          placeholder="Enter start date"
                          value={this.state.start_date}
                          name="start_date"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>{" "}
                    <Form.Group className="col-lg-2 col-md-4 col-sm-12">
                      <Form.Label>End Date</Form.Label>
                      <div className="input-group ">
                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm"
                          type="date"
                          placeholder="Enter end date"
                          value={this.state.end_date}
                          name="end_date"
                          onChange={this.onChange}
                        ></input>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i
                              className="fa fa-user"
                              style={{ fontSize: "13px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                  </div>

                  <h4>Plants Config</h4>
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <Form.Group className="col-lg-3 col-md-3 col-sm-12">
                          <Form.Label>Sectors</Form.Label>
                          <div className="input-group ">
                            <input
                              onKeyPress={this.CheckKey}
                              className="form-control form-control-sm"
                              type="number"
                              placeholder="Enter sectors"
                              value={this.state.conf_sectors}
                              name="conf_sectors"
                              onChange={this.onChange}
                            ></input>
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i
                                  className="fa fa-user"
                                  style={{ fontSize: "13px" }}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group className="col-lg-3 col-md-3 col-sm-12">
                          <Form.Label>Tunnels</Form.Label>
                          <div className="input-group ">
                            <input
                              onKeyPress={this.CheckKey}
                              className="form-control form-control-sm"
                              type="number"
                              placeholder="Enter tunnels"
                              value={this.state.conf_tunnels}
                              name="conf_tunnels"
                              onChange={this.onChange}
                            ></input>
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i
                                  className="fa fa-user"
                                  style={{ fontSize: "13px" }}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group className="col-lg-3 col-md-3 col-sm-12">
                          <Form.Label>Rows</Form.Label>
                          <div className="input-group ">
                            <input
                              onKeyPress={this.CheckKey}
                              className="form-control form-control-sm"
                              type="number"
                              placeholder="Enter rows"
                              value={this.state.conf_rows}
                              name="conf_rows"
                              onChange={this.onChange}
                            ></input>
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i
                                  className="fa fa-user"
                                  style={{ fontSize: "13px" }}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group className="col-lg-3 col-md-3 col-sm-12">
                          <Form.Label>Cages</Form.Label>
                          <div className="input-group ">
                            <input
                              onKeyPress={this.CheckKey}
                              className="form-control form-control-sm"
                              type="number"
                              placeholder="Enter cages"
                              value={this.state.conf_cages}
                              name="conf_cages"
                              onChange={this.onChange}
                            ></input>
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i
                                  className="fa fa-user"
                                  style={{ fontSize: "13px" }}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-danger"
                    onClick={() => this.closeModal()}
                    name="cancel"
                    style={{ float: "right" }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary mr-2"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    {this.state.button_title}
                  </button>
                </DialogContent>
              </Dialog>
              <Card>
                <CardHeader title="Fields">
                  <CardHeaderToolbar>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.AddData()}
                      name="add_button"
                    >
                      Add New Field
                    </button>
                  </CardHeaderToolbar>
                </CardHeader>
                <>
                  <CardBody ref={(el) => (this.componentRef = el)}>
                    <div className="row d-none print">
                      <div className="col-sm-12">
                        <span>
                          <h2 className="text-center">List</h2>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-sm-12">
                        {" "}
                        <Select
                          styles={customStyles}
                          name="company_id"
                          options={this.state.allCompanies}
                          onChange={this.AllCompanyChanges}
                          value={this.state.allCompanies.filter(
                            ({ id }) => id === this.state.company
                          )}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <Select
                          styles={customStyles}
                          name="type_id"
                          options={this.state.allTypes}
                          onChange={this.AllTypeChanges}
                          value={this.state.allTypes.filter(
                            ({ id }) => id === this.state.type
                          )}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <SearchForTable onSubmitValue={this.onSubmitValue} />{" "}
                      </div>
                    </div>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th width="7%">
                            <span style={{ display: "flex" }}>Sr#</span>
                          </th>
                          <th width="10%">
                            <span style={{ display: "flex" }}>Company</span>
                          </th>
                          <th width="10%">
                            <span style={{ display: "flex" }}>Type</span>
                          </th>
                          <th width="10%">
                            <span style={{ display: "flex" }}>ID</span>
                          </th>
                          <th width="17%">
                            <span style={{ display: "flex" }}>Title</span>
                          </th>
                          <th width="17%">
                            <span style={{ display: "flex" }}>District</span>
                          </th>
                          <th width="19%">
                            <span style={{ display: "flex" }}>
                              Google Drive ID
                            </span>
                          </th>
                          <th width="10%" className="text-center">
                            Events
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data ? (
                          this.state.data.map((obj, k) => (
                            <tr>
                              <td>
                                {this.state.itemsPerPage *
                                  (this.state.pageNo - 1) +
                                  k +
                                  1}
                              </td>
                              <td>{obj.company?.title}</td>
                              <td>{obj.ui_field_type?.name}</td>
                              <td>{obj.uid}</td>
                              <td>{obj.title}</td>
                              <td>{obj.district}</td>
                              <td>{obj.gdrive_uid}</td>
                              <td className="text-center">
                                <div className="notPrint">
                                  <button
                                    onClick={() => this.UpdateData(obj)}
                                    className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                                    title="Edit details"
                                    ng-click="UpdateData(role)"
                                  >
                                    <span className="svg-icon svg-icon-md">
                                      <EditIcon />
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                                    title="Delete"
                                    ng-click="Delete(role.srNo)"
                                    onClick={() =>
                                      this.handleDeleteALert(obj.id)
                                    }
                                  >
                                    <span className="svg-icon svg-icon-md">
                                      <DeleteIcon />
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h3>No data available.</h3>
                        )}
                      </tbody>
                      <tfoot className="notPrint">
                        <tr>
                          <td align="center" colSpan="8">
                            <div className="pull-right">
                              <button
                                id="btnFirst"
                                onClick={() => this.PaginateData(1)}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                First
                              </button>
                              <button
                                id="btnPrevious"
                                onClick={() => this.PreviousPageData()}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                Previous
                              </button>
                              {this.state.totalPages !== "0" ? (
                                <button
                                  id="bnt1"
                                  onClick={() => this.GetPageData("1")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  1
                                </button>
                              ) : null}
                              {this.state.totalPages > "1" ? (
                                <button
                                  id="bnt2"
                                  onClick={() => this.GetPageData("2")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  2
                                </button>
                              ) : null}
                              {this.state.totalPages > "2" ? (
                                <button
                                  id="bnt3"
                                  onClick={() => this.GetPageData("3")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  3
                                </button>
                              ) : null}
                              {this.state.totalPages > "3" ? (
                                <button
                                  id="bnt4"
                                  onClick={() => this.GetPageData("4")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  4
                                </button>
                              ) : null}
                              {this.state.totalPages > "4" ? (
                                <button
                                  id="bnt5"
                                  onClick={() => this.GetPageData("5")}
                                  className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                                >
                                  5
                                </button>
                              ) : null}
                              <button
                                id="btnNext"
                                onClick={() => this.NextPageData()}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                Next
                              </button>
                              <button
                                id="btnLast"
                                onClick={() =>
                                  this.PaginateData(this.state.totalPages)
                                }
                                className="btn btn-rounded btn-sm btn-outline-primary"
                              >
                                Last
                              </button>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <label className="btn btn-primary  mt-2 pulll-left">
                                  {this.state.pageNo * this.state.itemsPerPage -
                                    this.state.itemsPerPage}
                                  -
                                  {this.state.pageNo === this.state.totalPages
                                    ? this.state.totalItems
                                    : this.state.pageNo *
                                      this.state.itemsPerPage}
                                  / {this.state.totalItems} items
                                </label>
                              </div>
                              <div className="col-6">
                                <div className="row">
                                  <div className="col-3">
                                    <select
                                      className="form-control form-control-sm mt-3"
                                      name="itemsPerPage"
                                      onChange={this.onChange}
                                    >
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                      <option value="500">500</option>
                                    </select>
                                  </div>
                                  <div className="col-9">
                                    <button className="btn btn-primary mt-2 pull-right col-8">
                                      Page: {this.state.pageNo} /
                                      {this.state.totalPages}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </CardBody>
                </>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Field);